/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'cpu-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 6a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5z"/><path pid="1" d="M5.5.5a.5.5 0 00-1 0V2A2.5 2.5 0 002 4.5H.5a.5.5 0 000 1H2v1H.5a.5.5 0 000 1H2v1H.5a.5.5 0 000 1H2v1H.5a.5.5 0 000 1H2A2.5 2.5 0 004.5 14v1.5a.5.5 0 001 0V14h1v1.5a.5.5 0 001 0V14h1v1.5a.5.5 0 001 0V14h1v1.5a.5.5 0 001 0V14a2.5 2.5 0 002.5-2.5h1.5a.5.5 0 000-1H14v-1h1.5a.5.5 0 000-1H14v-1h1.5a.5.5 0 000-1H14v-1h1.5a.5.5 0 000-1H14A2.5 2.5 0 0011.5 2V.5a.5.5 0 00-1 0V2h-1V.5a.5.5 0 00-1 0V2h-1V.5a.5.5 0 00-1 0V2h-1zm1 4.5h3A1.5 1.5 0 0111 6.5v3A1.5 1.5 0 019.5 11h-3A1.5 1.5 0 015 9.5v-3A1.5 1.5 0 016.5 5"/>',
    },
});
